import React from "react"

import Layout from "../components/Layout"

const Home = () => {
  return (
    <Layout title="Ziv Karmi | Software Engineer in NYC">
      <p>Page not found</p>
    </Layout>
  )
}

export default Home
